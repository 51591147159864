import { createMuiTheme } from "@material-ui/core"
import cyan from "@material-ui/core/colors/cyan"
import amber from "@material-ui/core/colors/amber"

const theme = createMuiTheme({
  palette: {
    //type: 'dark',
    primary: {
      main: cyan[500],
      contrastText: "#fff",
    },
    secondary: {
      main: amber[500],
    },
  },
})

export default theme
