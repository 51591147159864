import React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  searchedKeyword: "",
  relevantSearchId: "",
  playerVideoId: "",
}

function reducer(state, action) {
  switch (action.type) {
    case "SEARCH": {
      return {
        ...state,
        relevantSearchId: "",
        searchedKeyword: action.payload.keyword || state.searchedKeyword,
      }
    }

    case "SEARCH_CLEAR": {
      return {
        ...state,
        searchedKeyword: "",
      }
    }

    case "SEARCH_RELEVANT": {
      return {
        ...state,
        relevantSearchId: action.payload.id || "",
        searchedKeyword: action.payload.id || state.searchedKeyword,
      }
    }

    case "PLAY": {
      return {
        ...state,
        playerVideoId: action.payload.id || "",
      }
    }

    case "PLAYER_CLOSE": {
      return {
        ...state,
        playerVideoId: "",
      }
    }

    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
